import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Accessibility } from 'accessibility';

const AccessibilityTool = () => {
    useEffect(() => {
        const options = {
            modules: {
                decreaseText: true,
                increaseText: true,
                invertColors: true,
                increaseTextSpacing: false,
                decreaseTextSpacing: false,
                increaseLineHeight: false,
                decreaseLineHeight: false,
                grayHues: true,
                underlineLinks: true,
                bigCursor: true,
                readingGuide: true,
                textToSpeech: false,
                speechToText: false,
                disableAnimations: true,
            },
        };

        const initializeAccessibility = () => {
            new Accessibility(options);
        };

        if (document.readyState === 'complete') {
            initializeAccessibility();
        } else {
            window.addEventListener('load', initializeAccessibility);
            return () => {
                window.removeEventListener('load', initializeAccessibility);
            };
        }
    }, []);

    return null;
};

const AccessibilityPortal = () => {
    return ReactDOM.createPortal(
        <AccessibilityTool />,
        document.getElementById('accessibility-root')
    );
};

export default AccessibilityPortal;
