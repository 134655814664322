import './App.css';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import ScrollToPosition from './common/components/ScrollToPosition';
import ErrorBoundary from './pages/ErrorBoundary';
import { FusionAuthProvider } from '@fusionauth/react-sdk';
import ScrollToTop from './common/components/ScrollToTop';
import FallbackUI from './common/components/FallbackUI';

import {
    CartProvider,
    MedusaProvider,
    SessionCartProvider,
} from 'medusa-react';
import { QueryClient, useIsFetching } from '@tanstack/react-query';
import NewsletterModal from './common/modals/NewsletterModal/NewsletterModal';
import ChatComponent from './common/components/CustomerChatComponent/ChatComponent';
import AccessibilityPortal from './common/components/AccessibilityTool/AccessibilityTool';
import CartDrawer from './common/components/CartDrawer';
import { AppCartProvider } from './context/AppCartContext';
import { MAIN_TITLE } from './common/constants';

import { MedusaDataProvider } from './context/MedusaDataContext';
import useIsSmallScreen from './hooks/useIsSmallScreen';

const queryClient = new QueryClient();
// const NewsletterModal = lazy(
//     () => import('./common/modals/NewsletterModal/NewsletterModal')
// );
const Header = lazy(() => import('./common/components/Header/Header'));
const Home = lazy(() => import('./pages/Home/Home'));
const Footer = lazy(() => import('./common/components/Footer/Footer'));
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const Registries = lazy(() => import('./pages/Registries/Registries'));
const Defenders = lazy(() => import('./pages/Defenders/Defenders'));
const TrackYourOrder = lazy(
    () => import('./pages/TrackYourOrder/TrackYourOrder')
);
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const Faq = lazy(() => import('./pages/Faq/Faq'));
const TopSellers = lazy(() => import('./pages/TopSellers/TopSellers'));
const TopRegistries = lazy(
    () => import('./pages/TopRegistries/RegistryProduct')
);
const EventSpaces = lazy(() => import('./pages/EventSpaces/EventSpaces'));
const NurseryDesignCenter = lazy(
    () => import('./pages/NurseryDesignCenter/NurseryDesignCenter')
);
const LoyaltyPoints = lazy(() => import('./pages/LoyaltyPoints/LoyaltyPoints'));
const ShippingAndDelivery = lazy(
    () => import('./pages/ShippingAndDelivery/ShippingAndDelivery')
);
const ReturnsAndRefunds = lazy(
    () => import('./pages/ReturnsAndRefunds/ReturnsAndRefunds')
);
const PriceMatchingPolicy = lazy(
    () => import('./pages/PriceMatchingPolicy/PriceMatchingPolicy')
);
const ProductView = lazy(() => import('./pages/Product/ProductView'));
const ShopPage = lazy(() => import('./pages/Shop/ShopPage'));
const BlogDetailedView = lazy(() => import('./pages/Blog/BlogDetailedView'));
const ShoppingCart = lazy(() => import('./pages/Cart/ShoppingCart'));
const Callback = lazy(() => import('./pages/Login/Callback'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));
const Blog = lazy(() => import('./pages/Blog/Blog'));
const GiftCard = lazy(() => import('./pages/GiftCard/GiftCard'));

const ThankyouPage = lazy(
    () => import('./pages/Checkout/components/ThankyouPage')
);

// footer pages
const WebsiteAccessibilty = lazy(
    () => import('./pages/FooterPages/WebsiteAccessibilty')
);
const TermsConditions = lazy(
    () => import('./pages/FooterPages/TermsConditions')
);
const CATransparency = lazy(() => import('./pages/FooterPages/CATransparency'));
const PrivacyPolicy = lazy(() => import('./pages/FooterPages/PrivacyPolicy'));
const DoNotSellInfo = lazy(() => import('./pages/FooterPages/DoNotSellInfo'));
const Checkout = lazy(() => import('./pages/Checkout/Checkout'));
const StoreLocations = lazy(
    () => import('./pages/StoreLocations/StoreLocations')
);
const ProductCategory = lazy(
    () => import('./pages/ProductCategory/ProductCategory')
);

const config = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    serverUrl: process.env.REACT_APP_FUSIONAUTH_URL,
    shouldAutoFetchUserInfo: true,
    shouldAutoRefresh: true,
    onRedirect: () => {},
    scope: 'openid email profile offline_access',
};

// Swap tab title for each page in the routes
const medusaProviderURL = process.env.REACT_APP_MEDUSA_BACKEND_URL;

const Page = ({ component: Component, title }) => {
    // useEffect(() => {
    //     if (![ProductView, BlogDetailedView].includes(Component)) {
    //         document.title = title ? `${title} - ${MAIN_TITLE}` : MAIN_TITLE;
    //     }
    // }, [title]);

    return <Component />;
};

Page.propTypes = {
    component: PropTypes.any.isRequired,
    title: PropTypes.string,
};

function App() {
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const isSmallScreen = useIsSmallScreen();

    return (
        <Router>
            <FusionAuthProvider {...config}>
                <MedusaProvider
                    queryClientProviderProps={{ client: queryClient }}
                    baseUrl={medusaProviderURL}
                >
                    <CartProvider>
                        <SessionCartProvider>
                            <MedusaDataProvider>
                                <AppCartProvider>
                                    <ErrorBoundary>
                                        <div className="bob-website">
                                            <Suspense fallback={<FallbackUI />}>
                                                <ScrollToTop />
                                                <ScrollToPosition
                                                    position={200}
                                                />
                                                <Header openModal={openModal} />
                                                <Routes>
                                                    <Route
                                                        path="/"
                                                        element={
                                                            <Page
                                                                component={Home}
                                                                title=""
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/about"
                                                        element={
                                                            <Page
                                                                component={
                                                                    AboutUs
                                                                }
                                                                title="About Us"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/top-sellers"
                                                        element={
                                                            <Page
                                                                component={
                                                                    TopSellers
                                                                }
                                                                title="Top Sellers"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/top-registries"
                                                        element={
                                                            <Page
                                                                component={
                                                                    TopRegistries
                                                                }
                                                                title="Top Registries"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/callback"
                                                        element={
                                                            <Page
                                                                component={
                                                                    Callback
                                                                }
                                                                title="Callback"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/product/:handle"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ProductView
                                                                }
                                                                title="Product View"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/cart"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ShoppingCart
                                                                }
                                                                title="Cart"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/shop-categories-page"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ShopPage
                                                                }
                                                                title="Shop Categories Page"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/blog/:blogHandle"
                                                        element={
                                                            <Page
                                                                component={
                                                                    BlogDetailedView
                                                                }
                                                                title="title"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/checkout"
                                                        element={
                                                            <Page
                                                                component={
                                                                    Checkout
                                                                }
                                                                title="Checkout Order"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/contact"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ContactUs
                                                                }
                                                                title="Contact Us"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/faq"
                                                        element={
                                                            <Page
                                                                component={Faq}
                                                                title="FAQ"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/thank-you"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ThankyouPage
                                                                }
                                                                title="Thank you"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/store-locations"
                                                        element={
                                                            <Page
                                                                component={
                                                                    StoreLocations
                                                                }
                                                                title="Store Locations"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/event-spaces"
                                                        element={
                                                            <Page
                                                                component={
                                                                    EventSpaces
                                                                }
                                                                title="Event Spaces"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/nursery-design-center"
                                                        element={
                                                            <Page
                                                                component={
                                                                    NurseryDesignCenter
                                                                }
                                                                title="Nursery Design Center"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/shipping-delivery"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ShippingAndDelivery
                                                                }
                                                                title="Shipping and Deliverys"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/price-matching-policy"
                                                        element={
                                                            <Page
                                                                component={
                                                                    PriceMatchingPolicy
                                                                }
                                                                title="Price Matching Policy"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/returns-refunds"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ReturnsAndRefunds
                                                                }
                                                                title="Returns and Refunds"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/rewards"
                                                        element={
                                                            <Page
                                                                component={
                                                                    LoyaltyPoints
                                                                }
                                                                title="Rewards"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/blog"
                                                        element={
                                                            <Page
                                                                component={Blog}
                                                                title="Blog"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/gift-card"
                                                        element={
                                                            <Page
                                                                component={
                                                                    GiftCard
                                                                }
                                                                title="Gift Card"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/baby-registry"
                                                        element={
                                                            <Page
                                                                component={
                                                                    Registries
                                                                }
                                                                title="Registries"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/bob-defenders"
                                                        element={
                                                            <Page
                                                                component={
                                                                    Defenders
                                                                }
                                                                title="Defenders"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/track-your-order"
                                                        element={
                                                            <Page
                                                                component={
                                                                    TrackYourOrder
                                                                }
                                                                title="TrackYourOrder"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="*"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ErrorPage
                                                                }
                                                                title="Page not found"
                                                            />
                                                        }
                                                    />
                                                    {/* Dark footer pages */}
                                                    <Route
                                                        path="/website-accessibility"
                                                        element={
                                                            <Page
                                                                component={
                                                                    WebsiteAccessibilty
                                                                }
                                                                title="Website Accessibility"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/terms-conditions-of-use"
                                                        element={
                                                            <Page
                                                                component={
                                                                    TermsConditions
                                                                }
                                                                title="Terms & Conditions of Use"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/california-consumer-privacy-act-ccpa-compliance"
                                                        element={
                                                            <Page
                                                                component={
                                                                    CATransparency
                                                                }
                                                                title="California Consumer Privacy Act (CCPA) Compliance"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/privacy-security-policy"
                                                        element={
                                                            <Page
                                                                component={
                                                                    PrivacyPolicy
                                                                }
                                                                title="Privacy & Security Policy"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/product-category/:category/:subCategory?/*"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ProductCategory
                                                                }
                                                                title="Product Category"
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/product-category/search/:searchCategory?/:attribute?/*"
                                                        element={
                                                            <Page
                                                                component={
                                                                    ProductCategory
                                                                }
                                                                title="Product Category"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/do-not-sell-my-personal-info"
                                                        element={
                                                            <Page
                                                                component={
                                                                    DoNotSellInfo
                                                                }
                                                                title="Do Not Sell My Personal Info"
                                                            />
                                                        }
                                                    />
                                                </Routes>
                                                <Footer />
                                                <CartDrawer />
                                                {!isSmallScreen && (
                                                    <ChatComponent />
                                                )}
                                                <AccessibilityPortal />
                                            </Suspense>
                                        </div>
                                    </ErrorBoundary>
                                </AppCartProvider>
                            </MedusaDataProvider>
                        </SessionCartProvider>
                    </CartProvider>
                </MedusaProvider>
                {showModal && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <NewsletterModal
                            showModal={showModal}
                            handleClose={closeModal}
                        />
                    </Suspense>
                )}
            </FusionAuthProvider>
            {/* <AccessibilityTool /> */}
        </Router>
    );
}

export default App;
