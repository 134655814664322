import Medusa from '@medusajs/medusa-js';
import { getAsciiCodes } from '../utils/mathUtils';

const regionId = process.env.REACT_APP_REGION_ID;
const medusaKey = process.env.REACT_APP_MEDUSA_KEY ?? 'xyz';
const medusa = new Medusa({
    baseUrl: process.env.REACT_APP_MEDUSA_BACKEND_URL,
    maxRetries: 3,
    apiKey: medusaKey,
});

export const CheckUserExists = async (email) => {
    try {
        const isExists = await medusa.auth.exists(email);
        return isExists;
    } catch (error) {
        throw new Error('Medusa User exist check failed: ' + error.message);
    }
};

export const RegisterCustomer = (createCustomer, customerData) => {
    try {
        createCustomer.mutate(customerData, {
            onSuccess: ({ customer }) => {
                return customer;
            },
            onError: (error) => {
                throw new Error('Medusa Registration failed: ' + error.message);
            },
        });
    } catch (error) {
        throw new Error('Medusa Registration failed: ' + error.message);
    }
};

export const LoginCustomer = async (email, password) => {
    try {
        await medusa.auth.authenticate({
            email: email,
            password: password,
        });
    } catch (error) {
        throw new Error('Medusa Authentication failed: ' + error.message);
    }
};

export const LogoutCustomer = async () => {
    try {
        const res = await medusa.auth.deleteSession();
        return res;
    } catch (error) {
        throw new Error('Medusa Logout failed: ' + error.message);
    }
};

export const GeneratePassword = (email) => {
    const str = email.split('@')[0].replaceAll('.', '').toLowerCase();
    const temp = getAsciiCodes(str).slice(0, 3).join().replaceAll(',', '');
    const password = str[0].toUpperCase() + str.slice(1) + '#@' + temp;

    return password;
};

export const GetShippingOptions = async () => {
    try {
        const res = await medusa.shippingOptions.list({
            region_id: regionId,
        });
        return res;
    } catch (error) {
        throw new Error('Medusa shipping options failed: ' + error.message);
    }
};

export const GetShippingProfiles = async () => {
    try {
        const res = await medusa.admin.shippingProfiles.list();
        return res;
    } catch (error) {
        throw new Error('Medusa shipping profiled failed: ' + error.message);
    }
};

export const GetStripePayment = async (setClientSecret, cartId) => {
    try {
        await medusa.carts.createPaymentSessions(cartId).then(({ cart }) => {
            // check if stripe is selected
            const isStripeAvailable = cart.payment_sessions?.some(
                (session) => session.provider_id === 'stripe'
            );
            if (!isStripeAvailable) {
                return;
            }

            // select stripe payment session
            medusa.carts
                .setPaymentSession(cartId, {
                    provider_id: 'stripe',
                })
                .then(({ cart }) => {
                    setClientSecret(cart.payment_session.data.client_secret);
                });
        });
    } catch (error) {
        console.log(error);
    }
};

export const getCategoryDetails = async (handle) => {
    try {
        return await medusa.admin.productCategories.list({ handle: handle });
    } catch (error) {
        console.error(error);
    }
};

export const checkVariantQuantity = async (id) => {
    return await medusa.products.variants.retrieve(id);
};

export const fetchCategoryById = async (categoryId) => {
    try {
        const response =
            await medusa.admin.productCategories.retrieve(categoryId);
        const data = await response.product_category;
        return data;
    } catch (error) {
        console.error('Error fetching category:', error);
    }
};
