export const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(script);
        script.onerror = () =>
            reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
    });
};

export const containsOnly = (arr, allowedValues) => {
    const allowedSet = new Set(allowedValues);
    return arr.every((item) => allowedSet.has(item));
};
