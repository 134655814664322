import React from 'react';
import RoundSpinner from './Spinner/RoundSpinner';

const FallbackUI = () => (
    <div className="fallback-ui">
        {/* <Spinner animation="border" role="status"></Spinner> */}
        <RoundSpinner />
    </div>
);

export default FallbackUI;
