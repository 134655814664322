import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import './styles/QuantityIncrementer.css';

const QuantityIncrementer = ({ quantity, onQuantityChange, item, style }) => {
    const [quantityInput, setQuantityInput] = useState(quantity);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setQuantityInput(quantity);
    }, [quantity]);

    const handleIncrement = () => {
        const newQuantity = quantityInput + 1;
        if (newQuantity <= item.inventory_quantity) {
            setQuantityInput(newQuantity);
            onQuantityChange(item.id, newQuantity);
        } else {
            setErrorMessage('No stock available');
        }
    };

    const handleDecrement = () => {
        if (quantityInput > 1) {
            const newQuantity = quantityInput - 1;
            setQuantityInput(newQuantity);
            onQuantityChange(item.id, newQuantity);
            setErrorMessage('');
        } else if (quantityInput === 1) {
            setErrorMessage('');
        }
    };

    return (
        <>
            <div
                className={`${style ? style : 'mb-2 mt-3'} d-flex flex-row align-items-center quantity-incrementer-container`}
            >
                <button className="increment-btn" onClick={handleDecrement}>
                    -
                </button>
                <Form.Group controlId="quantity">
                    <Form.Control
                        type="number"
                        placeholder=""
                        className="quantity-number-input"
                        value={quantityInput}
                        onChange={() => {}}
                    />
                </Form.Group>
                <button className="increment-btn" onClick={handleIncrement}>
                    +
                </button>
            </div>
            {errorMessage && (
                <small className="text-danger quantity-incrementer-error">
                    {errorMessage}
                </small>
            )}
        </>
    );
};

export default QuantityIncrementer;
