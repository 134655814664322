import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ChatComponent() {
    const location = useLocation();
    const [shouldLoadChat, setShouldLoadChat] = useState(false);
    const hiddenChatPaths = [
        /^\/product\/[^/]+$/,
        /^\/checkout$/,
        /^\/product-category\/.+$/,
    ];

    const shouldHideChat = hiddenChatPaths.some((regex) =>
        regex.test(location.pathname)
    );

    useEffect(() => {
        if (!shouldHideChat) {
            // Set a timer for 5 minutes (300,000 milliseconds)
            const timer = setTimeout(() => {
                setShouldLoadChat(true);
            }, 300000); // 5 minutes delay

            // Clean up the timer if the component unmounts or the route changes
            return () => clearTimeout(timer);
        }
    }, [shouldHideChat]);

    useEffect(() => {
        if (shouldLoadChat && !shouldHideChat) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;

            script.src =
                'https://embed.tawk.to/665380f59a809f19fb355cf3/1hur2vgft';
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');

            document.body.appendChild(script);

            // Clean up the script when the component unmounts or the route changes
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [shouldLoadChat, shouldHideChat]);

    return <div></div>;
}

export default ChatComponent;
