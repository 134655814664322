import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToPosition = ({ position }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: position, behavior: 'smooth' });
    }, [pathname, position]);

    return null;
};

ScrollToPosition.propTypes = {
    position: PropTypes.any,
};

export default ScrollToPosition;
