/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import './styles/CartDrawer.css';
import {
    Accordion,
    Badge,
    Col,
    Offcanvas,
    Row,
    Spinner,
} from 'react-bootstrap';
import cartIcon from '../../assets/icons/empty-bag.svg';

import QuantityIncrementer from './QuantityIncrementer';
import { useAppCart } from '../../context/AppCartContext';
import { useNavigate } from 'react-router-dom';
import { useCreateCart, useCreateLineItem, useUpdateCart } from 'medusa-react';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import { useMedusaData } from '../../context/MedusaDataContext';
import { checkVariantQuantity } from '../../api/medusaService';

const CartDrawer = () => {
    const {
        show,
        handleClose,
        cartItems,
        handleShow,
        removeCartItem,
        updateCartItemQuantity,
        shippingClass,
    } = useAppCart();
    const id = localStorage.getItem('cart_id');
    const { userAccount } = useMedusaData();
    const { points, userInfo, isLoggedIn, error } = userAccount;
    const regionId = process.env.REACT_APP_REGION_ID;
    const navigate = useNavigate();

    const createCart = useCreateCart();
    const [cartId, setCartId] = useState();
    const createLineItem = useCreateLineItem(id ? id : cartId);
    const updateCart = useUpdateCart(id);
    const [isExpanded, setIsExpanded] = useState(false);
    const email = !error && isLoggedIn && userInfo ? userInfo.email : undefined;
    const isSmallScreen = useIsSmallScreen();
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isChecked, setIsChecked] = useState(false);
    const [hideSection, setHideSection] = useState(false);

    // Function to handle checkbox change
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setIsChecked(checked);
        setHideSection(checked);
    };

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const isEmpty = cartItems.length === 0;

    const createCartItems = () => {
        const items = [];

        cartItems.map((item) => {
            const { variant, quantity } = item;
            const meta = {};

            if (variant.meta.metaOptions.length > 0) {
                variant.meta.metaOptions.map((option) => {
                    meta[`${option.name}`] = option.value;
                });
            }

            if (variant.meta.metaAttributes.length > 0) {
                variant.meta.metaAttributes.map((attri) => {
                    meta[`${attri.name}`] = attri.value;
                });
            }

            meta['shipping_mode'] = variant.meta.selectedMode.value;
            meta['shipping_class'] = variant.meta.shipping_class;

            items.push({
                variant_id: item.variant.id,
                quantity: quantity,
                metadata: meta,
            });
        });

        return items;
    };

    const handleCheckout = async () => {
        for (let index = 0; index < cartItems.length; index++) {
            const res = await checkVariantQuantity(cartItems[index].variant.id);
            if (res && res.variant.inventory_quantity <= 0) {
                setErrorMsg(`${res.variant.title} is not available.`);
                break;
            }
        }
        setIsLoading(true);

        createCart.mutate(
            {
                region_id: regionId,
            },
            {
                onSuccess: ({ cart }) => {
                    setCartId(cart.id);
                    localStorage.setItem('cart_id', cart.id);
                },
            }
        );
    };

    useEffect(() => {
        if (cartId) {
            const items = createCartItems();

            // after empty cart got created we are adding the cart items
            items.map((item) => {
                updateCart.mutate(
                    {
                        email: email,
                    },
                    {
                        onSuccess: ({ cart }) => {},
                    }
                );

                createLineItem.mutate(
                    {
                        variant_id: item.variant_id,
                        quantity: item.quantity,
                        metadata: item.metadata,
                    },
                    {
                        onSuccess: ({ cart }) => {
                            setIsLoading(false);
                            handleClose();
                            navigate('/checkout');
                        },
                        onError: () => {
                            setErrorMsg(
                                `${item.variant.title} is not available.`
                            );
                        },
                    }
                );
            });
        }
    }, [cartId]);

    return (
        <div className="cart-drawer-wrapper">
            {/* Floating Button */}
            <button onClick={handleShow} className="cart-floating-button">
                <div className="cart-button-inner-wrapper">
                    <Badge className="cart-notification-badge">
                        {cartItems.length}
                    </Badge>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-cart3"
                        viewBox="0 0 16 16"
                    >
                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                    </svg>
                </div>
            </button>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header
                    className="cart-offcanvas-header shadow-sm bg-white"
                    closeButton
                >
                    <Offcanvas.Title>
                        <div className="position-relative d-flex align-items-center justify-content-center">
                            <img
                                src={cartIcon}
                                height={50}
                                width={50}
                                className="cart-empty-img"
                                alt="empty-cart"
                            />{' '}
                            <div
                                className="position-absolute"
                                style={{
                                    top: '15px',
                                    left:
                                        cartItems.length === 1
                                            ? '21px'
                                            : '19px',
                                }}
                            >
                                {cartItems.length}
                            </div>
                        </div>
                        <span>Your Cart</span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="cart-offcanvas-body">
                    <div
                        className={`${isEmpty ? 'overflow-hidden' : ''} cart-drawer-content`}
                    >
                        {isEmpty ? (
                            <>
                                <p className="cart-empty-txt">
                                    Your cart is empty
                                </p>
                                <a
                                    className="main-btn-outline cart-empty-btn"
                                    href="/shop-categories-page"
                                >
                                    Return to Shop
                                </a>
                            </>
                        ) : (
                            <>
                                {cartItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className="cart-product-wrapper bg-white shadow-sm w-100"
                                    >
                                        <Row className="d-flex align-items-center position-relative cart-product-row">
                                            <Col
                                                lg={4}
                                                className="align-self-start w-auto"
                                            >
                                                <img
                                                    src={
                                                        item?.variant?.images
                                                            .length > 0
                                                            ? item.variant
                                                                  .images[0].url
                                                            : item.variant.meta
                                                                    .baseImage
                                                              ? item.variant
                                                                    .meta
                                                                    .baseImage
                                                                    .url
                                                              : ''
                                                    }
                                                    className="cart-product-img"
                                                />
                                            </Col>
                                            <Col
                                                className="text-start cart-drawer-card-col cart-drawer-content-col"
                                                lg={8}
                                            >
                                                <strong className="cart-product-title">
                                                    {' '}
                                                    <p className="mb-1 font-16">
                                                        {
                                                            item.variant.meta
                                                                ?.mainTitle
                                                        }
                                                    </p>
                                                </strong>
                                                <p className="mb-1 shop-cart-variant-title">
                                                    {item.variant.title}
                                                </p>
                                                <QuantityIncrementer
                                                    quantity={item.quantity}
                                                    onQuantityChange={
                                                        updateCartItemQuantity
                                                    }
                                                    item={item.variant}
                                                />
                                                <p className="mb-1 font-12">
                                                    Shipping:{' '}
                                                    {
                                                        item.variant.meta
                                                            .selectedMode.title
                                                    }
                                                </p>

                                                {!item.variant?.meta
                                                    ?.isSimple && (
                                                    <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>
                                                                <div className="d-flex flex-row">
                                                                    <p
                                                                        className="cart-see-more-link"
                                                                        onClick={
                                                                            handleToggleExpand
                                                                        }
                                                                    >
                                                                        {isExpanded
                                                                            ? 'See less'
                                                                            : 'See more'}
                                                                    </p>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body className="p-0">
                                                                <div className="cart-expanded-content">
                                                                    {item
                                                                        .variant
                                                                        .meta
                                                                        .metaAttributes &&
                                                                        item
                                                                            .variant
                                                                            .meta
                                                                            .metaAttributes
                                                                            .length >
                                                                            0 &&
                                                                        item.variant.meta.metaAttributes.map(
                                                                            (
                                                                                attribute,
                                                                                index
                                                                            ) => (
                                                                                <p
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        attribute.name
                                                                                    }

                                                                                    :{' '}
                                                                                    {
                                                                                        attribute.value
                                                                                    }
                                                                                </p>
                                                                            )
                                                                        )}

                                                                    {item
                                                                        .variant
                                                                        .meta
                                                                        .metaOptions &&
                                                                        item
                                                                            .variant
                                                                            .meta
                                                                            .metaOptions
                                                                            .length >
                                                                            0 &&
                                                                        item.variant.meta.metaOptions.map(
                                                                            (
                                                                                option,
                                                                                index
                                                                            ) => (
                                                                                <p
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option.name
                                                                                    }

                                                                                    :{' '}
                                                                                    {
                                                                                        option.value
                                                                                    }
                                                                                </p>
                                                                            )
                                                                        )}
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                )}
                                                <p className="m-0 font-18">
                                                    $
                                                    {
                                                        item.variant.meta
                                                            .basePrice
                                                    }
                                                </p>
                                            </Col>
                                            <a
                                                className="align-self-end position-absolute cart-product-remove"
                                                onClick={() =>
                                                    removeCartItem(
                                                        item.variant.id
                                                    )
                                                }
                                            >
                                                <small>remove</small>
                                            </a>
                                        </Row>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div className="cart-drawer-footer shadow-lg bg-white">
                        {isEmpty ? (
                            <>
                                <button
                                    className="main-btn continue-shopping-btn m-0"
                                    onClick={handleClose}
                                >
                                    Continue Shopping
                                </button>
                            </>
                        ) : (
                            <>
                                <h4>
                                    <strong>
                                        Subtotal: $
                                        {cartItems
                                            .reduce(
                                                (total, item) =>
                                                    total +
                                                    item.variant.meta
                                                        .basePrice *
                                                        item.quantity,
                                                0
                                            )
                                            .toFixed(2)}
                                    </strong>
                                </h4>
                                {!hideSection && (
                                    <p className="cart-drawer-collectible-points">
                                        Total Collectable Points:{' '}
                                        {cartItems
                                            .reduce(
                                                (total, item) =>
                                                    total +
                                                    item.variant.meta
                                                        .basePrice *
                                                        item.quantity,
                                                0
                                            )
                                            .toFixed(2)}{' '}
                                    </p>
                                )}
                                {points >= 0 && isLoggedIn && (
                                    <>
                                        <p className="cart-drawer-avail-points">
                                            Available Loyalty Points: {points}
                                        </p>
                                        <label className="cart-use-point-label">
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                disabled={points === 0}
                                            />
                                            Use Points
                                        </label>
                                    </>
                                )}
                                <p className="cart-drawer-info-txt">
                                    Shipping, taxes, and discounts calculated at
                                    checkout.
                                </p>
                                <a href="/cart" className="main-btn">
                                    View Cart
                                </a>
                                {!isSmallScreen && (
                                    <a
                                        className="main-btn"
                                        onClick={handleClose}
                                    >
                                        Continue Shopping
                                    </a>
                                )}
                                <a
                                    className="main-btn"
                                    onClick={handleCheckout}
                                >
                                    {isLoading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            className="me-2"
                                        />
                                    )}
                                    Checkout -{' '}
                                    {!isChecked ? (
                                        <>
                                            ${' '}
                                            {(
                                                cartItems.reduce(
                                                    (total, item) =>
                                                        total +
                                                        item.variant.meta
                                                            .basePrice *
                                                            item.quantity,
                                                    0
                                                ) +
                                                shippingClass.amount / 100
                                            ).toFixed(2)}
                                        </>
                                    ) : (
                                        <>
                                            <span className="cart-points-total-span">
                                                ${' '}
                                                {(
                                                    cartItems.reduce(
                                                        (total, item) =>
                                                            total +
                                                            item.variant.meta
                                                                .basePrice *
                                                                item.quantity,
                                                        0
                                                    ) +
                                                    shippingClass.amount / 100
                                                ).toFixed(2)}
                                            </span>
                                            ${' '}
                                            {(
                                                Math.max(
                                                    0,
                                                    cartItems.reduce(
                                                        (total, item) =>
                                                            total +
                                                            item.variant.meta
                                                                .basePrice *
                                                                item.quantity,
                                                        0
                                                    ) +
                                                        shippingClass.amount /
                                                            100
                                                ) - points
                                            ).toFixed(2)}
                                        </>
                                    )}
                                </a>
                            </>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default CartDrawer;
