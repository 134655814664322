import { fetchData } from '../api';
import { BOB_BACKEND } from '../common/constants';

export const fetchUserPoints = async (setPointsLoading, email) => {
    setPointsLoading(true);

    try {
        const url = BOB_BACKEND.getPoints;
        const fetchUrl = url.replace('{email}', email);
        const result = await fetchData(fetchUrl);
        return result;
    } catch (error) {
        console.error(error);
    } finally {
        setPointsLoading(false);
    }
};

export function generateDiscountCode() {
    // Get the current date and format it as YYYYMMDD
    const date = new Date();
    const formattedDate = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}`;

    // Generate a random string of specified length
    function getRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters[randomIndex];
        }
        return randomString;
    }

    // Define the length of the random string
    const randomStringLength = 8;
    const randomString = getRandomString(randomStringLength);

    // Concatenate to form the discount code
    const discountCode = `POINTS_${formattedDate}_${randomString}`;

    return discountCode;
}

export const calculateCartTotal = (cart, shippingClass, points) => {
    let subtotal = cart.subtotal / 100;
    const discountTotal = cart.discount_total / 100;
    const giftCardTotal = cart.gift_card_total / 100;
    const shippingAmount = shippingClass.amount / 100;
    const taxTotal = cart.tax_total / 100;

    // Deduct points from subtotal
    subtotal = Math.max(0, subtotal - points);

    // Calculate the final total
    const total =
        subtotal - discountTotal - giftCardTotal + shippingAmount + taxTotal;

    return Math.max(total, 0);
};
