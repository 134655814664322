import React, { useState } from 'react';
import {
    Modal,
    Col,
    Row,
    Form,
    Button,
    OverlayTrigger,
    Tooltip,
    Toast,
    Spinner,
} from 'react-bootstrap';
import './NewsletterModal.css';
import PropTypes from 'prop-types';
import modalImg from '../../../assets/images/modals/baby-with-laptop.webp';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newsletterSchema } from '../../forms/validation';
import { postData } from '../../../api';
import { BOB_BACKEND } from '../../constants';

const NewsletterModal = ({ showModal, handleClose }) => {
    const [isSuccess, setIsSuccess] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const toggleShow = () => setShowToast((flag) => !flag);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(newsletterSchema),
    });

    const onSubmit = async (data) => {
        setLoading(true);
        const body = {
            name: data.name,
            email: data.email,
            phone: data.phoneNumber,
            isContact: false,
        };

        if (data.phoneNumber === '') delete body.phone;
        try {
            const res = await postData(BOB_BACKEND.newsletter, body);
            if (res?.status === 200) {
                setIsSuccess(true);
                setShowToast(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        reset();
        handleClose();
    };

    const watchCheckbox = watch('subscribe');

    return (
        <Modal
            show={showModal}
            onHide={handleModalClose}
            centered
            className="newsletter-modal"
        >
            <Modal.Body className="p-0 pe-lg-3">
                <div className="pop-modal-close-btn" onClick={handleModalClose}>
                    &times;
                </div>
                <Row>
                    <Col md={6}>
                        <img
                            src={modalImg}
                            alt="Product"
                            className="modal-image"
                        />
                    </Col>
                    <Col md={6} className="p-2">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <p className="newsletter-title">
                                Stay in the Loop with Our Weekly Newsletter
                            </p>
                            <ul className="newsletter-modal-content">
                                <li className="mb-3">
                                    <strong>New Arrivals:</strong> Discover the
                                    latest products for your little one.
                                </li>

                                <li className="mb-3">
                                    <strong>Exclusive Deals:</strong> Access
                                    special offers and discounts.
                                </li>

                                <li className="mb-3">
                                    <strong>Latest Blog Posts:</strong> Get
                                    insights and tips from our baby care
                                    experts.
                                </li>
                            </ul>
                            <Form.Group
                                controlId="formName"
                                className="position-relative"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        errors.name ? (
                                            <Tooltip id="tooltip-name">
                                                {errors.name.message}
                                            </Tooltip>
                                        ) : (
                                            <></>
                                        )
                                    }
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        {...register('name', {
                                            required: 'Name is required',
                                        })}
                                        isInvalid={!!errors.name}
                                    />
                                </OverlayTrigger>
                            </Form.Group>
                            <Form.Group
                                controlId="formEmail"
                                className="position-relative"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        errors.email ? (
                                            <Tooltip id="tooltip-email">
                                                {errors.email.message}
                                            </Tooltip>
                                        ) : (
                                            <></>
                                        )
                                    }
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        {...register('email')}
                                        isInvalid={!!errors.email}
                                    />
                                </OverlayTrigger>
                            </Form.Group>

                            <Form.Group
                                controlId="formPhoneNumber"
                                className="position-relative"
                            >
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        errors.phoneNumber ? (
                                            <Tooltip id="tooltip-phoneNumber">
                                                {errors.phoneNumber.message}
                                            </Tooltip>
                                        ) : (
                                            <></>
                                        )
                                    }
                                >
                                    <Form.Control
                                        type="tel"
                                        placeholder="Phone Number"
                                        {...register('phoneNumber')}
                                        isInvalid={!!errors.phoneNumber}
                                    />
                                </OverlayTrigger>
                            </Form.Group>
                            <Form.Group controlId="formNewsletter">
                                <Form.Check
                                    className="newsletter-check-label"
                                    type="checkbox"
                                    label="By subscribing, you agree to receive periodic updates from Babys On Broadway"
                                    {...register('subscribe')}
                                    isInvalid={errors.subscribe}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.subscribe?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                className="share-photo-btn"
                                type="submit"
                                disabled={!watchCheckbox}
                            >
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="submit-btn-loader"
                                    />
                                )}
                                Subscribe
                            </Button>
                            <Toast
                                show={showToast}
                                onClose={toggleShow}
                                autohide
                                delay={2000}
                                animation
                                bg={isSuccess ? 'success' : 'warning'}
                            >
                                <Toast.Body>
                                    {isSuccess ? (
                                        <i className="fa-regular fa-circle-check app-toast-body"></i>
                                    ) : (
                                        <i className="fa-solid fa-circle-exclamation app-toast-body"></i>
                                    )}
                                    {isSuccess
                                        ? 'Subscription Successful'
                                        : 'Oops!, something went wrong.'}
                                </Toast.Body>
                            </Toast>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

NewsletterModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default NewsletterModal;
