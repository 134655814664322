import { useAddShippingMethodToCart, useSessionCart } from 'medusa-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { GetShippingOptions, GetShippingProfiles } from '../api/medusaService';
import Medusa from '@medusajs/medusa-js';
import { containsOnly } from '../utils/commonUtils';

const AppCartContext = createContext();

export const useAppCart = () => useContext(AppCartContext);

export const AppCartProvider = ({ children }) => {
    const {
        addItem,
        removeItem,
        total,
        totalItems,
        items: cartItems,
        updateItem,
        getItem,
        clearItems,
    } = useSessionCart();

    const [show, setShow] = useState(false);
    const [shippingClass, setShippingClass] = useState({ amount: 0, id: null });
    const [shippingClasses, setShippingClasses] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const cartId = localStorage.getItem('cart_id');
    const [cart, setCart] = useState(cartItems);
    const addShippingMethod = useAddShippingMethodToCart(cartId);

    const medusaKey = process.env.REACT_APP_MEDUSA_KEY ?? 'xyz';
    const medusaClient = new Medusa({
        baseUrl: process.env.REACT_APP_MEDUSA_BACKEND_URL,
        maxRetries: 3,
        apiKey: medusaKey,
    });

    // const cartItems = Cart_items;
    const handleShow = () => {
        refreshCartItems();
        setShow(true);
    };
    const handleClose = () => setShow(false);

    const addToAppCart = (product) => {
        addItem({ variant: product, quantity: 1 });
        handleShow();

        // clearItems();
    };

    const removeCartItem = (id) => {
        removeItem(id);
    };

    const clearCart = () => {
        clearItems();
    };

    const refreshCartItems = () => {
        setCart(cartItems);
    };

    const updateCartItemQuantity = (id, quantity) => {
        const item = getItem(id);
        updateItem(id, {
            ...item,
            quantity: quantity,
        });
    };

    const filterShippingOptions = (options, profileId) => {
        return options.filter((option) => {
            if (
                option.metadata &&
                option.metadata.shipping_class !== undefined &&
                ![
                    'Local Pickup St Cloud',
                    'Local Pickup Little Falls',
                ].includes(option.metadata.shipping_class)
            ) {
                if (
                    option.metadata.shipping_class !== '' &&
                    shippingClasses.includes(option.metadata.shipping_class)
                ) {
                    return option.profile_id === profileId;
                } else if (
                    shippingClasses.includes('') &&
                    option.metadata.shipping_class === ''
                ) {
                    return option.profile_id === profileId;
                }
            }
            return false;
        });
    };

    const findMaxAmountOption = (options, profileId) => {
        const filteredOptions = filterShippingOptions(options, profileId);
        if (filteredOptions.length === 0) return null;

        let maxAmount = -Infinity;
        let maxOption = null;

        filteredOptions.forEach((option) => {
            if (option.amount > maxAmount) {
                maxAmount = option.amount;
                maxOption = option;
            }
        });

        return maxOption;
    };

    const updateShippingProfiles = async (formData) => {
        const res = await GetShippingProfiles();
        let shipping_id;
        if (res) {
            for (const profile of res.shipping_profiles) {
                if (
                    !['Default Shipping Profile', 'Gift Card Profile'].includes(
                        profile.name
                    )
                ) {
                    if (profile.metadata?.states.includes(formData.state)) {
                        shipping_id = profile.id;
                        break;
                    }
                }
            }
        }

        return shipping_id;
    };

    const updateShippingCost = async (formData) => {
        if (
            containsOnly(shippingClasses, [
                'Local Pickup St Cloud',
                'Local Pickup Little Falls',
            ])
        ) {
            const res = await GetShippingOptions();
            if (res.shipping_options && res.shipping_options.length > 0) {
                const shippingOption = res.shipping_options.find((option) =>
                    [
                        'Local Pickup St Cloud',
                        'Local Pickup Little Falls',
                    ].includes(option.metadata.shipping_class)
                );
                setShippingClass(shippingOption);
                return true;
            } else {
                return false;
            }
        } else {
            const profileId = await updateShippingProfiles(formData);
            const res = await GetShippingOptions();

            if (
                profileId &&
                res.shipping_options &&
                res.shipping_options.length > 0
            ) {
                const highestOption = findMaxAmountOption(
                    res.shipping_options,
                    profileId
                );
                if (highestOption !== null) {
                    if (highestOption.metadata.shipping_class === '') {
                        if (subTotal < 50) {
                            setShippingClass(highestOption);
                        } else {
                            const finalShippingOption =
                                res.shipping_options.find(
                                    (option) =>
                                        option.name ===
                                        'Free shipping for MN, IA, WI, IL, SD, ND'
                                );
                            setShippingClass(finalShippingOption);
                        }
                    } else {
                        setShippingClass(highestOption);
                    }

                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    };

    const updateCartShipping = () => {
        addShippingMethod.mutate(
            {
                option_id: shippingClass.id,
            },
            {
                onSuccess: ({ cart }) => {
                    return true;
                },
            }
        );
    };

    useEffect(() => {
        const shippingClass = [];
        let subTotal = 0;
        if (cartItems.length > 0) {
            cartItems.map((item) => {
                const { variant } = item;

                if (variant.meta.selectedMode.value === 'shipping') {
                    shippingClass.push(
                        variant.meta.shipping_class
                            ? variant.meta.shipping_class
                            : ''
                    );

                    subTotal = subTotal + variant.meta.basePrice;
                } else if (variant.meta.selectedMode.value === 'stcloud') {
                    shippingClass.push('Local Pickup St Cloud');
                } else if (variant.meta.selectedMode.value === 'littlefalls') {
                    shippingClass.push('Local Pickup Little Falls');
                }
            });

            setShippingClasses(shippingClass);
            setSubTotal(subTotal);
        }
    }, [cartItems]);

    return (
        <AppCartContext.Provider
            value={{
                show,
                handleShow,
                handleClose,
                cartItems,
                addToAppCart,
                removeCartItem,
                updateCartItemQuantity,
                total,
                totalItems,
                updateShippingCost,
                updateShippingProfiles,
                shippingClass,
                cartId,
                updateCartShipping,
                medusaClient,
                clearCart,
                refreshCartItems,
            }}
        >
            {children}
        </AppCartContext.Provider>
    );
};
