import * as yup from 'yup';

export const sharePhotoSchema = yup.object().shape({
    file: yup
        .mixed()
        .required('File is required')
        .test('fileSize', 'File size is too large', (value) => {
            return value[0]?.size <= 2000000; // 2MB
        }),
    textArea: yup
        .string()
        .required('Please tell us why you like this product')
        .min(10, 'Must be at least 10 characters'),
    name: yup
        .string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters'),
});

export const newsletterSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: yup.string(),
    subscribe: yup.bool(),
});
