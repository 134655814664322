/* eslint-disable no-useless-catch */
import axios from 'axios';
const APP_URL = process.env.REACT_APP_BACKEND_URL;
export const fetchData = async (url) => {
    try {
        const response = await axios.get(`${APP_URL}${url}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const postData = async (url, data) => {
    try {
        const response = await axios.post(`${APP_URL}${url}`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchMultiData = async (urlList) => {
    try {
        const promiseList = urlList.map((url) => axios.get(url));
        const response = await Promise.all(promiseList);
        return response;
    } catch (error) {
        throw error;
    }
};
