/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null, didReload: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('ErrorBoundary caught an error', error, errorInfo);
        this.setState({ error, errorInfo });

        // Check if localStorage has data and if the page has already reloaded once
        if (!this.state.didReload && Object.keys(localStorage).length > 0) {
            localStorage.clear();
            this.setState({ didReload: true }, () => {
                window.location.reload();
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="d-flex align-items-center justify-content-center error-boundary-container flex-column">
                    <i className="fa-solid fa-triangle-exclamation mb-4 app-error-boundary"></i>
                    <h1>Oops! Something went wrong.</h1>
                    <p className="mt-2">
                        We're currently experiencing technical issues. Please
                        try again later. If the issue persists, please reach out
                        to our support team for help.
                    </p>

                    <button className="btn btn-primary mb-3 d-flex flex-row footer-send-btn btn btn-outline-dark" onClick={() => { localStorage.clear(); window.location.reload(); }}>
                        Try again!
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
