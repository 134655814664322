import React from 'react';

export const BOB_BACKEND = {
    booking: '/bookings',
    newsletter: '/newsletter/store',
    getPoints: '/get-points/{email}',
    nurseryShareVision: '/booking-data/eventspaces',
    // strapi URL
    getLargeBanner:
        'https://beta.strapi.babysonbroadway.com/api/home?populate[0]=bannersLarge&populate[1]=bannersLarge.bannerImg',
    getSmallBanner:
        'https://beta.strapi.babysonbroadway.com/api/home?populate[0]=bannersSmall&populate[1]=bannersSmall.bannerImg',
};

export const TOP_SCROLL_VALUE = 300;

export const SHIPPING_MODE = [
    {
        title: 'Delivery',
        id: 'shipping',
        icon: (typeSelected) => (
            <i
                className="fa-solid fa-truck"
                style={{
                    color: typeSelected === 'shipping' ? '#000' : '#ababab',
                }}
            ></i>
        ),
    },
    {
        title: 'St Cloud',
        id: 'stcloud',
        icon: (typeSelected) => (
            <i
                className="fa-regular fa-building"
                style={{
                    color: typeSelected === 'stcloud' ? '#000' : '#ababab',
                }}
            ></i>
        ),
    },
    {
        title: 'Little Falls',
        id: 'littlefalls',
        icon: (typeSelected) => (
            <i
                className="fa-regular fa-building"
                style={{
                    color: typeSelected === 'littlefalls' ? '#000' : '#ababab',
                }}
            ></i>
        ),
    },
];

export const MAIN_TITLE = 'Babys on Broadway | Retail';

export const TYPESENSE_PRODUCT_CATEGORIES = {
    BABY_HEALTH_AND_SAFETY: 'baby_health_and_safety',
    BATH_AND_POTTY: 'bath_and_potty',
    BREASTFEEDING: 'breastfeeding',
    CLOTHING_AND_ACCESSORIES: 'clothing_and_accessories',
    GEAR: 'gear',
    HSA_FSA: 'hsa_fsa',
    MATERNITY_AND_POSTPARTUM: 'maternity_and_postpartum',
    MEAL_TIME: 'meal_time',
    NURSERY_FURNITURE_AND_SLEEP: 'nursery_furniture_and_sleep',
};

export const APP_URL = 'https://store.live.babysonbroadway.com';
