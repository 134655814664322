import React, { createContext, useContext, useEffect, useState } from 'react';
import categoryData from '../data/categoryData.json';
import prodByCate from '../data/productByCategory.json';
import youMayLike from '../data/youMayLike.json';
import toyDataJson from '../data/ttToysData.json';
import nurseryInBox from '../data/nurseryInBox.json';
import brandJson from '../data/brandData.json';
import { useFusionAuth } from '@fusionauth/react-sdk';
import { fetchUserPoints } from '../utils/cartUtils';

const MedusaDataContext = createContext();

export const useMedusaData = () => useContext(MedusaDataContext);

export const MedusaDataProvider = ({ children }) => {
    const { userInfo, isLoggedIn, error } = useFusionAuth();
    // const isLoggedIn = true;
    // const error = false;
    // const userInfo = { email: 'kristy.roagers@gmail.com' };

    const [prodCategories, setProdCategories] = useState([]);
    const [prodByCategory, setProdByCategory] = useState([]);
    const [youMayLikeData, setYouMayLikeData] = useState([]);
    const [nurseryData, setNurseryData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [toyData, setToyData] = useState([]);
    const [userAccount, setUserAccount] = useState({});
    const [refreshAccount, setRefreshAccount] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPoints = async () => {
            try {
                const res = await fetchUserPoints(setLoading, userInfo.email);
                if (res) {
                    setUserAccount({
                        userInfo: res?.data.customer,
                        error: error,
                        isLoggedIn: isLoggedIn,
                        points: res?.data?.total_points
                            ? res?.data?.total_points
                            : 0,
                        shipping: res?.data?.shipping_address,
                        billing: res?.data?.billing_address,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };
        if (userInfo) {
            isLoggedIn && fetchPoints();
        }
    }, [refreshAccount, isLoggedIn, userInfo]);

    useEffect(() => {
        const getCategoryList = async () => {
            const productCategoryList = [];
            categoryData.map((category) => {
                if (category.parent_category_id === null) {
                    const subCategoryArr = [];
                    const isHidden = [
                        'tt-toys-treats',
                        'bob-most-loved',
                        'best-sellers',
                        'shop-by-trimester',
                        'hsa-fsa-approved-items',
                        'uncategorized',
                        'holiday-catalog-items-2023',
                    ].includes(category.handle);
                    category?.category_children.map((sub) => {
                        subCategoryArr.push({
                            path: `/product-category/${category.handle}/${sub.handle}`,
                            label: sub.name,
                            imgURL: sub.metadata.image_url,
                            keyLabel: sub.handle,
                        });
                    });

                    productCategoryList.push({
                        title: category.name,
                        keyLabel: category.handle,
                        links: subCategoryArr,
                        isHidden: isHidden,
                        path: `/product-category/${category.handle}`,
                    });
                }
            });

            let index = productCategoryList.findIndex(
                (cat) => cat.keyLabel === 'nursery-sleep-essentials'
            );

            if (index !== -1) {
                let products = productCategoryList[index].links;

                // Split the products array into two halves
                let half = Math.ceil(products.length / 2);
                let firstHalf = products.slice(0, half);
                let secondHalf = products.slice(half);

                // Update the original object with the first half
                productCategoryList[index].links = firstHalf;

                // Insert a new object with the second half after the current object
                let newObject = {
                    ...productCategoryList[index],
                    name: '',
                    links: secondHalf,
                };
                productCategoryList.splice(index + 1, 0, newObject);
            }

            setProdByCategory(prodByCate);
            setYouMayLikeData(youMayLike);
            setToyData(toyDataJson);
            setNurseryData(nurseryInBox);

            const brands = [];
            brandJson.map((brand, index) => {
                const brandHandle = brand.name.split(' ').join('-');

                brands.push({
                    slideId: index,
                    imageURL: brand.value,
                    slideTitle: brand.name,
                    navLink: `/product-category/search/all/brand/${brandHandle}`,
                });
            });
            setBrandData(brands);

            setProdCategories(productCategoryList);
        };

        getCategoryList();
    }, []);

    // get region id for US

    return (
        <MedusaDataContext.Provider
            value={{
                prodCategories,
                prodByCategory,
                youMayLikeData,
                toyData,
                nurseryData,
                setRefreshAccount,
                userAccount,
                brandData,
            }}
        >
            {children}
        </MedusaDataContext.Provider>
    );
};
